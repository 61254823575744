<template>
    <v-container style="margin-top:60px; margin-bottom: 90px;" class="gray_bg_container"> 
        <v-container v-if="!linked||1">
           
      
     
    <v-card class="simple_card">
        <div  @click="epi_arch = !epi_arch"  style="    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;font-size: 19px;justify-content: space-between;padding-top: 11px;
    padding-bottom: 5px;">
             <img src="/img/arch_epi.png" style="height:30.23px;width: auto;">
             <img src="/img/arch_rect.png" style="height:14px;width: auto;" :class="{rotate: epi_arch}" class="rect">
            </div>
    </v-card>

    
    <v-card v-if="epi_arch" class="brand_card" style="margin-top: 25px;margin-bottom: 10px;">
          <v-row fluid style="margin-top: 25px;margin-bottom: 10px;">
                <v-col cols="1"></v-col>
                <v-col cols="10">
                    <p style="text-align: center; margin-bottom: 0;">
                  <b style=" font-family: 'Montserrat';  font-style: normal;  font-weight: 700;   font-size: 22px;    line-height: 24px;    text-align: center;   color: #4B3C32; ">{{$t('ordered_cert')}}:</b>
                </p>
                </v-col>
                <v-col cols="1"></v-col>
          </v-row>
    
         
    
           <!-- <v-container v-for="(order, i) in orders" :key="i">
            <v-card  elevation="10"  class="brand_card"> -->
    
              <v-row fluid class="ma-0" style="" v-for="(order, i) in orders_epi" :key="i"> 
                <v-col cols="12">
                  <span class="cert_data">
                  <font style="color:#F07300;">Номер:</font>&nbsp;&nbsp;<font style="font-weight: 400;">{{order['code']}}</font>
                </span><br>
                <span class="cert_data mb-3">
                  <font style="color:#F07300;">Діє до:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['end_date']}}</font>
                </span><br>
                <span class="cert_data mb-3">
                  <font style="color:#F07300;">Замовлено:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['r_date']}}</font>
                </span><br>
                <!-- <span>
                  <b v-if="order['is_used']=='1'" style="color:#9D1C2B">Використаний</b>
                  <b v-if="order['is_used']=='0'" style="color:green">Доступний</b>
    
                </span><br><br> -->
                  <center>
                <a class="lnk_cert mt-5" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'\', \'_blank\'); return false;'">Відкрити сертифікат</a><br>
                <a class="lnk_cert" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'&download=true\', \'_blank\'); return false;'">Завантажити сертифікат</a><br>
                <!-- <a class="lnk_cert_arch" :onclick="1">Архівувати сертифікат</a> -->
                
                </center>
                </v-col>
                <hr style="width: 95%;margin-left: 2.5%;">
                </v-row>
            <!-- </v-card>
          </v-container>    -->
      </v-card>
    
    
      <v-card class="simple_card" style="margin-top: 15px;">
        <div  @click="novus_arch = !novus_arch"  style="    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;font-size: 19px;justify-content: space-between;padding-top: 11px;
    padding-bottom: 5px;">
             <img src="/img/arch_novus.png" style="height:22.15px;width: auto;">
             <img src="/img/arch_rect.png" style="height:14px;width: auto;" :class="{rotate: novus_arch}" class="rect">
            </div>
    </v-card>


    <v-card v-if="novus_arch" class="brand_card" style="margin-top: 25px;margin-bottom: 10px;">
          <v-row fluid style="margin-top: 25px;margin-bottom: 10px;">
                <v-col cols="1"></v-col>
                <v-col cols="10">
                    <p style="text-align: center; margin-bottom: 0;">
                  <b style=" font-family: 'Montserrat';  font-style: normal;  font-weight: 700;   font-size: 22px;    line-height: 24px;    text-align: center;   color: #4B3C32; ">{{$t('ordered_cert')}}:</b>
                </p>
                </v-col>
                <v-col cols="1"></v-col>
          </v-row>
    
         
    
           <!-- <v-container v-for="(order, i) in orders" :key="i">
            <v-card  elevation="10"  class="brand_card"> -->
    
              <v-row fluid class="ma-0" style="" v-for="(order, i) in orders_novus" :key="i"> 
                <v-col cols="12">
                  <span class="cert_data">
                  <font style="color:#F07300;">Номер:</font>&nbsp;&nbsp;<font style="font-weight: 400;">{{order['code']}}</font>
                </span><br>
                <span class="cert_data mb-3">
                  <font style="color:#F07300;">Діє до:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['end_date']}}</font>
                </span><br>
                <span class="cert_data mb-3">
                  <font style="color:#F07300;">Замовлено:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['r_date']}}</font>
                </span><br>
                <!-- <span>
                  <b v-if="order['is_used']=='1'" style="color:#9D1C2B">Використаний</b>
                  <b v-if="order['is_used']=='0'" style="color:green">Доступний</b>
    
                </span><br><br> -->
                  <center>
                <a class="lnk_cert mt-5" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'\', \'_blank\'); return false;'">Відкрити сертифікат</a><br>
                <a class="lnk_cert" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'&download=true\', \'_blank\'); return false;'">Завантажити сертифікат</a><br>
                <!-- <a class="lnk_cert_arch" :onclick="1">Архівувати сертифікат</a> -->
                
                </center>
                </v-col>
                <hr style="width: 95%;margin-left: 2.5%;">
                </v-row>
            <!-- </v-card>
          </v-container>    -->
      </v-card>


      <v-card class="simple_card" style="margin-top: 15px;">
        <div  @click="silpo_arch = !silpo_arch"  style="    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;font-size: 19px;justify-content: space-between;padding-top: 11px;
    padding-bottom: 5px;">
             <img src="/img/arch_silpo.png" style="height:39.74px;width: auto;">
             <img src="/img/arch_rect.png" style="height:14px;width: auto;" :class="{rotate: silpo_arch}" class="rect">
            </div>
    </v-card>


    <v-card v-if="silpo_arch" class="brand_card" style="margin-top: 25px;margin-bottom: 10px;">
          <v-row fluid style="margin-top: 25px;margin-bottom: 10px;">
                <v-col cols="1"></v-col>
                <v-col cols="10">
                    <p style="text-align: center; margin-bottom: 0;">
                  <b style=" font-family: 'Montserrat';  font-style: normal;  font-weight: 700;   font-size: 22px;    line-height: 24px;    text-align: center;   color: #4B3C32; ">{{$t('ordered_cert')}}:</b>
                </p>
                </v-col>
                <v-col cols="1"></v-col>
          </v-row>
    
         
    
           <!-- <v-container v-for="(order, i) in orders" :key="i">
            <v-card  elevation="10"  class="brand_card"> -->
    
              <v-row fluid class="ma-0" style="" v-for="(order, i) in orders_silpo" :key="i"> 
                <v-col cols="12">
                  <span class="cert_data">
                  <font style="color:#F07300;">Номер:</font>&nbsp;&nbsp;<font style="font-weight: 400;">{{order['code']}}</font>
                </span><br>
                <span class="cert_data mb-3">
                  <font style="color:#F07300;">Діє до:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['end_date']}}</font>
                </span><br>
                <span class="cert_data mb-3">
                  <font style="color:#F07300;">Замовлено:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['r_date']}}</font>
                </span><br>
                <!-- <span>
                  <b v-if="order['is_used']=='1'" style="color:#9D1C2B">Використаний</b>
                  <b v-if="order['is_used']=='0'" style="color:green">Доступний</b>
    
                </span><br><br> -->
                  <center>
                <a class="lnk_cert mt-5" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'\', \'_blank\'); return false;'">Відкрити сертифікат</a><br>
                <a class="lnk_cert" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'&download=true\', \'_blank\'); return false;'">Завантажити сертифікат</a><br>
                <!-- <a class="lnk_cert_arch" :onclick="1">Архівувати сертифікат</a> -->
                
                </center>
                </v-col>
                <hr style="width: 95%;margin-left: 2.5%;">
                </v-row>
            <!-- </v-card>
          </v-container>    -->
      </v-card>



      <v-card class="simple_card" style="margin-top: 15px;">
        <div  @click="eva_arch = !eva_arch"  style="    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;font-size: 19px;justify-content: space-between;padding-top: 11px;
    padding-bottom: 5px;">
             <img src="/img/arch_eva.png" style="height:50.25px;width: auto;">
             <img src="/img/arch_rect.png" style="height:14px;width: auto;" :class="{rotate: eva_arch}" class="rect">
            </div>
    </v-card>


    <v-card v-if="eva_arch" class="brand_card" style="margin-top: 25px;margin-bottom: 10px;">
          <v-row fluid style="margin-top: 25px;margin-bottom: 10px;">
                <v-col cols="1"></v-col>
                <v-col cols="10">
                    <p style="text-align: center; margin-bottom: 0;">
                  <b style=" font-family: 'Montserrat';  font-style: normal;  font-weight: 700;   font-size: 22px;    line-height: 24px;    text-align: center;   color: #4B3C32; ">{{$t('ordered_cert')}}:</b>
                </p>
                </v-col>
                <v-col cols="1"></v-col>
          </v-row>
    
         
    
           <!-- <v-container v-for="(order, i) in orders" :key="i">
            <v-card  elevation="10"  class="brand_card"> -->
    
              <v-row fluid class="ma-0" style="" v-for="(order, i) in orders_eva" :key="i"> 
                <v-col cols="12">
                  <span class="cert_data">
              <font style="color:#F07300;">Номер:</font>&nbsp;&nbsp;<font style="font-weight: 400;">{{order['code'].split('_')[0]}}</font>
            </span><br>
            <span class="cert_data">
              <font style="color:#F07300;">Код:</font>&nbsp;&nbsp;<font style="font-weight: 400;">{{order['code'].split('_')[1]}}</font>
            </span><br>
                <span class="cert_data mb-3">
                  <font style="color:#F07300;">Діє до:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['end_date']}}</font>
                </span><br>
                <span class="cert_data mb-3">
                  <font style="color:#F07300;">Замовлено:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['r_date']}}</font>
                </span><br>
                <!-- <span>
                  <b v-if="order['is_used']=='1'" style="color:#9D1C2B">Використаний</b>
                  <b v-if="order['is_used']=='0'" style="color:green">Доступний</b>
    
                </span><br><br> -->
                  <center>
                <a class="lnk_cert mt-5" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'\', \'_blank\'); return false;'">Відкрити сертифікат</a><br>
                <a class="lnk_cert" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'&download=true\', \'_blank\'); return false;'">Завантажити сертифікат</a><br>
                <!-- <a class="lnk_cert_arch" :onclick="1">Архівувати сертифікат</a> -->
                
                </center>
                </v-col>
                <hr style="width: 95%;margin-left: 2.5%;">
                </v-row>
            <!-- </v-card>
          </v-container>    -->
      </v-card>





      <v-card class="simple_card" style="margin-top: 15px;">
        <div  @click="rozetka_arch = !rozetka_arch"  style="    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;font-size: 19px;justify-content: space-between;padding-top: 11px;
    padding-bottom: 5px;">
             <img src="/img/arch_rozetka.png" style="height:22.89px;width: auto;">
             <img src="/img/arch_rect.png" style="height:14px;width: auto;" :class="{rotate: rozetka_arch}" class="rect">
            </div>
    </v-card>


    <v-card v-if="rozetka_arch" class="brand_card" style="margin-top: 25px;margin-bottom: 10px;">
          <v-row fluid style="margin-top: 25px;margin-bottom: 10px;">
                <v-col cols="1"></v-col>
                <v-col cols="10">
                    <p style="text-align: center; margin-bottom: 0;">
                  <b style=" font-family: 'Montserrat';  font-style: normal;  font-weight: 700;   font-size: 22px;    line-height: 24px;    text-align: center;   color: #4B3C32; ">{{$t('ordered_cert')}}:</b>
                </p>
                </v-col>
                <v-col cols="1"></v-col>
          </v-row>
    
         
    
           <!-- <v-container v-for="(order, i) in orders" :key="i">
            <v-card  elevation="10"  class="brand_card"> -->
    
              <v-row fluid class="ma-0" style="" v-for="(order, i) in orders_rozetka" :key="i"> 
                <v-col cols="12">
                  <span class="cert_data">
                  <font style="color:#F07300;">Номер:</font>&nbsp;&nbsp;<font style="font-weight: 400;">{{order['code']}}</font>
                </span><br>
                <span class="cert_data mb-3">
                  <font style="color:#F07300;">Діє до:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['end_date']}}</font>
                </span><br>
                <span class="cert_data mb-3">
                  <font style="color:#F07300;">Замовлено:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['r_date']}}</font>
                </span><br>
                <!-- <span>
                  <b v-if="order['is_used']=='1'" style="color:#9D1C2B">Використаний</b>
                  <b v-if="order['is_used']=='0'" style="color:green">Доступний</b>
    
                </span><br><br> -->
                  <center>
                <a class="lnk_cert mt-5" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'\', \'_blank\'); return false;'">Відкрити сертифікат</a><br>
                <a class="lnk_cert" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'&download=true\', \'_blank\'); return false;'">Завантажити сертифікат</a><br>
                <!-- <a class="lnk_cert_arch" :onclick="1">Архівувати сертифікат</a> -->
                
                </center>
                </v-col>
                <hr style="width: 95%;margin-left: 2.5%;">
                </v-row>
            <!-- </v-card>
          </v-container>    -->
      </v-card>







      <v-card class="simple_card" style="margin-top: 15px;">
        <div  @click="wog_arch = !wog_arch"  style="    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;font-size: 19px;justify-content: space-between;padding-top: 11px;
    padding-bottom: 5px;">
             <img src="/img/arch_wog.png" style="height:28.4px;width: auto;">
             <img src="/img/arch_rect.png" style="height:14px;width: auto;" :class="{rotate: wog_arch}" class="rect">
            </div>
    </v-card>


    <v-card v-if="wog_arch" class="brand_card" style="margin-top: 25px;margin-bottom: 10px;">
          <v-row fluid style="margin-top: 25px;margin-bottom: 10px;">
                <v-col cols="1"></v-col>
                <v-col cols="10">
                    <p style="text-align: center; margin-bottom: 0;">
                  <b style=" font-family: 'Montserrat';  font-style: normal;  font-weight: 700;   font-size: 22px;    line-height: 24px;    text-align: center;   color: #4B3C32; ">{{$t('ordered_cert')}}:</b>
                </p>
                </v-col>
                <v-col cols="1"></v-col>
          </v-row>
    
         
    
           <!-- <v-container v-for="(order, i) in orders" :key="i">
            <v-card  elevation="10"  class="brand_card"> -->
    
              <v-row fluid class="ma-0" style="" v-for="(order, i) in orders_wog" :key="i"> 
                <v-col cols="12">
                  <span class="cert_data">
                  <font style="color:#F07300;">Номер:</font>&nbsp;&nbsp;<font style="font-weight: 400;">{{order['code']}}</font>
                </span><br>
                <span class="cert_data mb-3">
                  <font style="color:#F07300;">Діє до:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['end_date']}}</font>
                </span><br>
                <span class="cert_data mb-3">
                  <font style="color:#F07300;">Замовлено:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['r_date']}}</font>
                </span><br>
                <!-- <span>
                  <b v-if="order['is_used']=='1'" style="color:#9D1C2B">Використаний</b>
                  <b v-if="order['is_used']=='0'" style="color:green">Доступний</b>
    
                </span><br><br> -->
                  <center>
                <a class="lnk_cert mt-5" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'\', \'_blank\'); return false;'">Відкрити сертифікат</a><br>
                <a class="lnk_cert" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'&download=true\', \'_blank\'); return false;'">Завантажити сертифікат</a><br>
                <!-- <a class="lnk_cert_arch" :onclick="1">Архівувати сертифікат</a> -->
                
                </center>
                </v-col>
                <hr style="width: 95%;margin-left: 2.5%;">
                </v-row>
            <!-- </v-card>
          </v-container>    -->
      </v-card>












      <v-card class="simple_card" style="margin-top: 15px;">
        <div  @click="atb_arch = !atb_arch"  style="    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;font-size: 19px;justify-content: space-between;padding-top: 11px;
    padding-bottom: 5px;">
             <img src="/img/arch_atb.png" style="height:37.41px;width: auto;">
             <img src="/img/arch_rect.png" style="height:14px;width: auto;" :class="{rotate: atb_arch}" class="rect">
            </div>
    </v-card>


    <v-card v-if="atb_arch" class="brand_card" style="margin-top: 25px;margin-bottom: 10px;">
          <v-row fluid style="margin-top: 25px;margin-bottom: 10px;">
                <v-col cols="1"></v-col>
                <v-col cols="10">
                    <p style="text-align: center; margin-bottom: 0;">
                  <b style=" font-family: 'Montserrat';  font-style: normal;  font-weight: 700;   font-size: 22px;    line-height: 24px;    text-align: center;   color: #4B3C32; ">{{$t('ordered_cert')}}:</b>
                </p>
                </v-col>
                <v-col cols="1"></v-col>
          </v-row>
    
         
    
           <!-- <v-container v-for="(order, i) in orders" :key="i">
            <v-card  elevation="10"  class="brand_card"> -->
    
              <v-row fluid class="ma-0" style="" v-for="(order, i) in orders_atb" :key="i"> 
                <v-col cols="12">
                  <span class="cert_data">
              <font style="color:#F07300;">Номер:</font>&nbsp;&nbsp;<font style="font-weight: 400;">{{order['code'].split('_')[0]}}</font>
            </span><br>
            <span class="cert_data">
              <font style="color:#F07300;">Код:</font>&nbsp;&nbsp;<font style="font-weight: 400;">{{order['code'].split('_')[1]}}</font>
            </span><br>
                <span class="cert_data mb-3">
                  <font style="color:#F07300;">Діє до:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['end_date']}}</font>
                </span><br>
                <span class="cert_data mb-3">
                  <font style="color:#F07300;">Замовлено:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['r_date']}}</font>
                </span><br>
                <!-- <span>
                  <b v-if="order['is_used']=='1'" style="color:#9D1C2B">Використаний</b>
                  <b v-if="order['is_used']=='0'" style="color:green">Доступний</b>
    
                </span><br><br> -->
                  <center>
                <a class="lnk_cert mt-5" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'\', \'_blank\'); return false;'">Відкрити сертифікат</a><br>
                <a class="lnk_cert" :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'&download=true\', \'_blank\'); return false;'">Завантажити сертифікат</a><br>
                <!-- <a class="lnk_cert_arch" :onclick="1">Архівувати сертифікат</a> -->
                
                </center>
                </v-col>
                <hr style="width: 95%;margin-left: 2.5%;">
                </v-row>
            <!-- </v-card>
          </v-container>    -->
      </v-card>






    
    
    
    
  

    
    
    
              <v-overlay :value="isLoading">
          <v-progress-circular indeterminate
            color="red">
            
          </v-progress-circular>
          <br />
              </v-overlay>
        </v-container>
    </v-container>
    </template>
    <style lang="css" scoped>
    .rect{
        transition: transform 0.2s ease; 
    }
    .rotate{
        
        transform: rotate(180deg);
    }
    
    #exitico{
      background-image: url('/img/ico_exit.png');
      width:48px;height:48px;
      position:absolute;top:5px;left:5px;
    }
    #footer_my{
      background-image: url('/img/footer.png');
      width:100%;height:auto;min-height: 93px;min-width: 300px;;
      position:fixed;bottom:0; z-index: 2;left:0;right:0;margin: auto;
    }
    </style>
    <script>
    import btnrnd from "../components/btnrnd.vue";
    // eslint-disable-next-line no-unused-vars
    import axios from "axios";
    // eslint-disable-next-line no-unused-vars
    import { API_LOCATION } from "../../config"; 
    export default {
      name: "Info",
      components: {
        // eslint-disable-next-line vue/no-unused-components
        btnrnd,
      },
      data: () => ({
        sms_e:false,
          date: new Date().toISOString().substr(0, 10),
        linked:true,
        
        amount:null,
        personal_number: null,
        oppa_account:null,
        orders:[],
        transaction_id:"",
        sms_verify_code:"",
        modal: false,
        menu2: false,
        isLoading:false,
        sms_auth_dialog:false,
        auth_code:null,
        epi_arch:false,
        silpo_arch:false,
        novus_arch:false,
        eva_arch:false,
        rozetka_arch:false,
        
        atb_arch:false,
        
        wog_arch:false,
        month_lang:{
           
            'ua':['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']
          },
    
    
          accordion: 'inset',
        popout: false,
        inset: false,
        multiple: true,
        disabled: false,
        readonly: false,
        focusable: false,
        flat: false,
        hover: false,
        tile: false, 
        cert_model:[0],
        orders_epi:null
        
      }),
      computed: {
        used_certs: function(){
          return this.orders.filter((i) => i.is_used == "1");
        },
        unused_certs: function(){
          return this.orders.filter((i) => i.is_used == "0");
        },
        task1: function () {
          return this.cards.filter((i) => i.type == "1");
        },
        task2: function () {
          return this.cards.filter((i) => i.type == "2");
        },
        task3: function () {
          return this.cards.filter((i) => i.type == "3");
        },
        isLoggedIn: function () {
          return this.$store.getters.isLoggedIn;
        },
        authStatus: function () {
          return this.$store.getters.authStatus;
        },
        page_title: function () {
          return this.$store.getters.page_title;
        },
        fname: function () {
          return this.$store.getters.fname;
        },
        lname: function () {
          return this.$store.getters.lname;
        },
        points: function () {
          return this.$store.getters.points;
        },
        avatar: function () {
          return this.$store.getters.avatar;
        }, 
        title() {
            return this.$t('title')
          },
    
        date_locale(){
            if(this.$i18n.locale=='ru')
            {
                return 'ru-RU';
            }
            else
            {
                return 'ka-GE';
            }
        }
      },
      watch:{
          title: function(){
            this.$store.dispatch("page_title_set", this.title);
          },
          epi_arch: function(){
            this.get_epi_arch();
          },
          novus_arch: function(){
            this.get_novus_arch();
          },
          silpo_arch: function(){
            this.get_silpo_arch();
          },
          eva_arch: function(){
            this.get_eva_arch();
          },
          rozetka_arch: function(){
            this.get_rozetka_arch();
          },
          wog_arch: function(){
            this.get_wog_arch();
          }
          ,
          atb_arch: function(){
            this.get_atb_arch();
          }
      },
      mounted() {},
      created() {
        this.$store.dispatch("page_title_set", this.title);
        this.$store.dispatch("profileset");
        //this.get_silpo();
      },
      methods:{
        get_atb_arch: function () {     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_atb_eps_arch"           
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {      
        console.log(resp.data); 
        this.orders_atb=resp.data;
         this.isLoading=false;        
      });
      
    },
    get_wog_arch: function () {     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_wog_eps_arch"           
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {      
        console.log(resp.data); 
        this.orders_wog=resp.data;
         this.isLoading=false;        
      });
      
    },
    get_rozetka_arch: function () {     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_rozetka_eps_arch"           
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {      
        console.log(resp.data); 
        this.orders_rozetka=resp.data;
         this.isLoading=false;        
      });
      
    },
    get_eva_arch: function () {     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_eva_eps_arch"           
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {      
        console.log(resp.data); 
        this.orders_eva=resp.data;
         this.isLoading=false;        
      });
      
    },
    get_silpo_arch: function () {     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_silpo_eps_arch"           
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {      
        console.log(resp.data); 
        this.orders_silpo=resp.data;
         this.isLoading=false;        
      });
      
    },
    get_novus_arch: function () {     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_novus_eps_arch"           
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {      
        console.log(resp.data); 
        this.orders_novus=resp.data;
         this.isLoading=false;        
      });
      
    },
        get_epi_arch: function () {     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_epi_eps_arch"           
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {      
        console.log(resp.data); 
        this.orders_epi=resp.data;
         this.isLoading=false;        
      });
      
    },
        withdraw_sms_check:function(){
          this.isLoading=true;
          axios
          .post(
            API_LOCATION,
            {
              
              action: "check_sms_session_withdraw",
              auth_code:this.auth_code
              
            },
            { headers: { "content-type": "application/x-www-form-urlencoded" } }
          )
          .then((resp) => {
          
            console.log(resp.data); 
            if(resp.data['code']=='OK')
            {
            this.sms_auth_dialog=false; 
             this.withdraw_silpo();
             
            }
            else if(resp.data['code']=='wrong')
            {
              alert(this.$t('wrong_code'));
    
            }
            
            this.isLoading=false;
            
          });
    
        }
        ,
        withdraw_silpo: function () {
          console.log(this.sms_code);
          this.sms_auth_dialog=false;
    
          /*
          if(this.sms_code==0||this.sms_code!=1111){
            this.sms_auth_dialog=true;
            return;
          }
         */
          this.isLoading=true;
          axios
          .post(
            API_LOCATION,
            {
              
              action: "silpo_order_eps"
              
            },
            { headers: { "content-type": "application/x-www-form-urlencoded" } }
          )
          .then((resp) => {
          
            console.log(resp.data); 
            if(resp.data['code']=='OK')
            {
              
             this.get_silpo();
             alert(this.$t('order_success'));
            }else if(resp.data['code']=='no_session')
            {
              this.sms_auth_dialog=true;
    
            }
            else if(resp.data['code']=='insuf')
            {
              alert(this.$t('order_insuf'));
    
            }
            else if(resp.data['code']=='later_cert')
            {
              alert(this.$t('later_cert'));
    
            }
            this.isLoading=false;
            
          });
          
        },
        get_silpo: function () {
         
          this.isLoading=true;
          axios
          .post(
            API_LOCATION,
            {
              
              action: "get_silpo_eps" 
              
            },
            { headers: { "content-type": "application/x-www-form-urlencoded" } }
          )
          .then((resp) => {
          
            console.log(resp.data); 
            this.orders=resp.data;
             this.isLoading=false;
            
          });
          
        },
        
      }
    };
    </script>
    
    <i18n>
    {
      "ua":{
          "title": "Обмін балів",
          
          "cert_txt":"Натисни «Замовити», щоб отримати сертифікат номіналом 200 грн. ",
          "btn_order":"Замовити",
          "cert_del_txt":"Отриманий сертифікат покажи на касі у будь-якому вигляді: зображенням у iTeam, скріншотом або роздруківкою.<br><br>Слідкуй за терміном дії сертифікату! Ми не зможемо продовжити його дію або повернути бали.",
          "order_success":"Сертифікат замовлено! Збережіть його на телефон або зробіть скріншот. У період тимчасової зупинки програми відкрити сертифікати через iTeam буде неможливо.",
          "order_insuf":"Недостатньо балів",
          "ordered_cert":"Замовлені сертифікати",
          "later_cert":"Замовлення сертифікатів Сільпо тимчасово недоступне. Будь ласка, скористайтесь іншим видом «Емоцій»",
          "wrong_code":"Невірний код"
          
    
    
          
    
      }
    }
    </i18n>